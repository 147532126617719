import { React, useEffect, useRef, useState, useContext } from 'react'
import Grid from '@material-ui/core/Grid';
import './mediaPlayer.css'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CustomizedContentContext from '../contexts/CustomizedContentContext';
import AssignmentService from '../services/AssignmentService';
import { useHistory, useLocation } from 'react-router-dom';

function MediaPlayer() {

    const history = useHistory();
    const currentLoaction = useLocation();

    const {
        timerCount,
        setTimerCount,
        customizedContent,
        playBackData,
        setShowStatus,
        setCheckStatus
    } = useContext(CustomizedContentContext);

    const timoutTimer = useRef();
    const vdoPlyer = useRef();
    const [playerStatus, setPlayerStatus] = useState(-1);

    useEffect(() => {

        const listen = history.listen((location) => {
            if (currentLoaction !== location) {
                if (timoutTimer.current !== undefined) {
                    clearInterval(timoutTimer.current);
                    timoutTimer.current = 0;
                }
            }
        });

        return () => listen();

    }, [history, currentLoaction]);

    const secondsToTime = (e) => {

        if (e >= 0) {
            const h = Math.floor(e / 3600).toString().padStart(2, '0'),
                m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
                s = Math.floor(e % 60).toString().padStart(2, '0');
            return h + ':' + m + ':' + s;
        }

        return '00:00:00';
    }

    // Load the Player related scripts and load the player once its loaded
    useEffect(() => {

        const loadPlayer = () => {
            vdoPlyer.current = new window.VdoPlayer({
                otp: playBackData?.otp,
                playbackInfo: playBackData?.playbackInfo,
                theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
                container: document.getElementById("mediaContainer"),
                loop: true
            });

            setCheckStatus(1);
        }

        const loadScript = () => {

            const playerScript = document.createElement("script");
            playerScript.src = "https://player.vdocipher.com/playerAssets/1.6.10/vdo.js";
            document.body.appendChild(playerScript);

            const runTimerOperations = () => {

                setPlayerStatus(vdoPlyer.current?.status);

                const newTimerCount = timerCount - vdoPlyer.current?.totalPlayed;
                const timerCounter = document.getElementById("tp");

                if (timerCounter) {
                    timerCounter.innerHTML = secondsToTime(newTimerCount);
                }

                if (newTimerCount % 5 === 0 && newTimerCount !== -100) {

                    if (customizedContent.id != null && newTimerCount !== -100) {

                        AssignmentService.updateCustomizedContent(customizedContent.id, newTimerCount)
                            .then(() => { })
                            .catch(() => { });
                    }
                }

                if (newTimerCount !== -100 && newTimerCount <= 0) {
                    vdoPlyer.current?.pause();
                    setCheckStatus(3);
                    window.location.reload(); // Refresh the page when the play limit is exceeded
                }
            }

            playerScript.onload = () => {
                if (vdoPlyer.current === undefined && playBackData?.otp) {

                    loadPlayer();
                    timoutTimer.current = setInterval(runTimerOperations, 1000);
                }
            }
        }

        if (vdoPlyer.current === undefined) {
            loadScript();
        }

        return () => clearInterval(timoutTimer.current);

    }, [playBackData, setTimerCount, timerCount, setCheckStatus, customizedContent, setShowStatus])

    
    return (
        <>
            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#FFFF' }}>
                <Grid item xs={12} sm={12} md={12}>
                    <div className='mediaPlayerWrapper'>
                        <div className='container' style={{ display: (playerStatus === 0 || playerStatus === 2 || playerStatus === 3) ? 'grid' : 'none' }}>
                            <div className="item">
                                <IconButton aria-label="btnplay" id="btn_audio_play" onClick={() => vdoPlyer.current?.play()} sx={{ display: 'block' }}>
                                    <PlayCircleFilledIcon size="large" sx={{ width: '10em', height: '10em' }} />
                                </IconButton>
                            </div>
                            <div className="circle-still" style={{ animationDelay: '0s' }}></div>
                        </div>
                        <div className='container' style={{ display: playerStatus === 1 ? 'grid' : 'none' }}>
                            <div className="item">
                                <IconButton aria-label="btnplay" id="btn_audio_play" onClick={() => vdoPlyer.current?.pause()} sx={{ display: 'block' }}>
                                    <PauseCircleFilledIcon size="large" sx={{ width: '10em', height: '10em' }} />
                                </IconButton>
                            </div>
                            <div className="circle" style={{ animationDelay: '0s' }}></div>
                            <div className="circle" style={{ animationDelay: '1s' }}></div>
                            <div className="circle" style={{ animationDelay: '2s' }}></div>
                        </div>
                        <div className='container' style={{ display: playerStatus === -1 ? 'grid' : 'none' }}>
                            <div className="item">
                                <CircularProgress style={{ color: 'white' }} size="large" sx={{ width: '10em', height: '10em' }} />
                            </div>
                            <div className="circle-still" style={{ animationDelay: '0s' }}></div>
                        </div>
                        <div className='container-sub'>
                            <div style={{ color: 'white' }}>
                                <div id="tp">{`${secondsToTime(timerCount)}`}</div>
                            </div>
                        </div>
                        <div className="player" id="mediaContainer" style={{ visibility: 'hidden', zIndex: -1 }}></div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default MediaPlayer